require("turbolinks").start()
import '../stylesheets/website';

import 'bootstrap';

document.addEventListener("turbolinks:load", () => {
  var articles_page = document.getElementsByClassName('articles show')
  if (articles_page.length > 0) {
    var links = Array.from(document.querySelectorAll('a[href^="http"'))
    links.forEach((link) => { link.target = "_blank" }) 
  }
})